<template>
  <v-container fluid>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('account.permission')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      ></v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
export default {
  data: (vm) => ({
    url: "/account/permissions/",
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("ID"),
        value: "id",
        width: "10%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("viewset_name"),
        value: "viewset_name",
        width: "10%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("action_name"),
        value: "action_name",
        width: "10%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("description"),
        value: "description",
        width: "10%",
      },
    ],
  }),
  mixins: [BaseTable],
  computed: {},
  methods: {
  },
  mounted() {
  }
};
</script>
